import { TestSurface } from './types';

export const TESTS = {
  'woo-delivery-addr-revision': {
    variants: {
      control: 25,
      test: 75
    },
    controlVariant: 'control',
    testSurfaces: [TestSurface.OOV4, TestSurface.CUSTOM_DOMAINS, TestSurface.TOAST_LOCAL]
  }
};
